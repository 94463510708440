<template>
  <div class="assessBox">
    <div class="app-container">
      <div class="titlebox">
        <el-button size="medium"
                   type="text"
                   @click="$router.go(-1)">返回</el-button>
        <div class="title">{{Viewform.userName}}-人岗评估</div>
      </div>
      <el-form ref="assessForm"
               :model="assessForm">
        <div class="qualityevaluation">
          <div v-for="(question ,index) in questions"
               :key="index">
            <div class="title">{{question.className}}</div>
            <el-form-item v-for="(indicator) in question.indicatorList"
                          :key="indicator.indicatorId "
                          :rules="[{  required: true}]"
                          :label="indicator.indicatorName">
              <div class="assessRadio">
                <el-radio-group @change="radiochange(indicator.indicatorId)"
                                v-model="answerList[indicator.indicatorId].selectedOptionId"
                                class="radioArr">
                  <el-radio v-for="(option ,optionIndex) in indicator.optionList"
                            :key="optionIndex"
                            :label="option.optionId">
                    <span style="color:#000;padding:0 5px;">{{option.content}}</span>
                  </el-radio>
                </el-radio-group>
                <span :id="'radio_'+indicator.indicatorId"
                      style="color:red;display:none">请选择选项</span>
              </div>
              <el-form-item label="描述依据"
                            :rules="[{required: true }]"
                            class="fwn">
                <div>
                  <el-input style="margin-top:-12px;"
                            type="textarea"
                            :autosize="{ minRows: 3, maxRows: 4}"
                            maxlength="200"
                            @change="remarkChange($event,indicator.indicatorId)"
                            show-word-limit
                            v-model="answerList[indicator.indicatorId].remark"></el-input>
                </div>
                <span :id="'textarea_'+indicator.indicatorId"
                      style="color:red;display:none">请填写内容</span>
              </el-form-item>
            </el-form-item>
            <el-divider></el-divider>
          </div>
          <div class="buttonBox">
            <el-button type="plain"
                       size="medium"
                       class="buttonBox_cancel"
                       @click=handleCancle>取消</el-button>
            <el-button type="success"
                       size="medium"
                       class="buttonBox_save"
                       :loading="saveLoading"
                       @click="save('assessForm')">保存</el-button>
            <el-button type="primary"
                       size="medium"
                       :loading="submitLoading"
                       @click="submit('assessForm')">提交</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <el-dialog title="匹配结果"
               :visible.sync="resultVisible"
               width="30%">
      <div style="    text-align: center;
    font-size: 20px;">
        <p><span>自评得分:</span><span style="color:red">{{result.score}}</span></p>
        <p><span>职等:</span><span style="color:red">{{result.jobEtc}}</span></p>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleSure">取 消</el-button>
        <el-button type="primary"
                   @click="handleSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as api from '@/api/peopelPostMatch';
export default {
  data () {
    return {
      isRules: false,
      Viewform: {
        id: '',
        assessorType: '',
        userName: '',
        comId: ''
      },
      currentItem: {},
      questions: [],
      answerList: {},
      assessForm: {},
      result: {},
      resultVisible: false,
      saveLoading: false,
      submitLoading: false
    };
  },
  watch: {
  },
  computed: {
    isStrategy () {
      return Number(this.assessForm.rankName) < 3;
    },
    isStrategyNote () {
      return Number(this.assessForm.rankName) === 0 || Number(this.assessForm.rankName) === 1;
    },
    isRankLevel () {
      return this.assessForm.rankName != '4';
    },
    isCompetencyNote () {
      return this.assessForm.rankName == '0';
    }
  },
  mounted () {
    if (this.$route.query) {
      this.Viewform.id = this.$route.query.id;
      this.Viewform.userName = this.$route.query.userName,
        this.Viewform.comId = this.$route.query.comId;
      this.getConfig();

    }
  },
  created () {
  },
  methods: {
    clearValidate (formName) {
      this.$refs[formName].clearValidate();
    },

    // 取消
    handleCancle () {
      this.$router.go(-1);
    },
    getConfig () {
      api.getConfig({request: {
          userId: this.Viewform.id
        } }).then((res) => {
        this.questions = res.classList;
        this.questions.forEach((indicator) => {
          indicator.indicatorList.forEach((val) => {
            this.$set(this.answerList, val.indicatorId, {
              'indicatorId': val.indicatorId,
              'selectedOptionId': '',
              'remark': ''
            });
          });
        });
        this.getDetail();
      });
    },
    radiochange (indicatorId) {
      document.getElementById(`radio_${indicatorId}`).style.display = 'none';
    },
    remarkChange (remark, indicatorId) {
      if (remark != '') {
        document.getElementById(`textarea_${indicatorId}`).style.display = 'none';
      }
    },
    // 获取数据回显
    getDetail () {
      api.getAnswerInfo({request: {
          userId: this.Viewform.id
        } }).then((res) => {
        if (res.selfList.length != 0) {
          // this.answerList = res.selfList
          res.selfList.forEach((val) => {
            this.$set(this.answerList, val.indicatorId, {
              'indicatorId': val.indicatorId,
              'selectedOptionId': val.selectedOptionId,
              'remark': val.remark
            });
          });
        }
      });
    },
    save () {
      const list = [];

      for (const i in this.answerList) {
        list.push(this.answerList[i]);
      }
      const params = {
        request: {
          userId: this.Viewform.id,
          comId: this.Viewform.comId,
          assessorType: 0,
          type: 1,
          list
        }
      };

      this.saveLoading = true;
      api.save(params).then(() => {
        this.$message.success('保存成功');
        this.$router.push({ path: '/peoplepostmatch' });
        this.saveLoading = false;
        // if (res && res.code == 0) {
        //   this.$message.success("保存成功");
        //   this.$router.push({ path: "/peoplepostmatch" });
        // } else {
        //   this.$message.error(res.message);
        // }
      });

    },
    submit (formName) {
      // this.isRules = true;
      let valid = false;

      const list = [];

      for (const i in this.answerList) {
        list.push(this.answerList[i]);
      }
      list.forEach((val) => {
        if (val.selectedOptionId == '') {
          if (document.getElementById(`radio_${val.indicatorId}`)) {
            document.getElementById(`radio_${val.indicatorId}`).style.display = 'block';
            valid = true;
          }

        } else if (document.getElementById(`radio_${val.indicatorId}`)) {
            document.getElementById(`radio_${val.indicatorId}`).style.display = 'none';
          }
        if (val.remark == '') {
          if (document.getElementById(`textarea_${val.indicatorId}`)) {
            document.getElementById(`textarea_${val.indicatorId}`).style.display = 'block';
            valid = true;
          }

        } else if (document.getElementById(`textarea_${val.indicatorId}`)) {
            document.getElementById(`textarea_${val.indicatorId}`).style.display = 'none';
          }
      });
      if (!valid) {

        this.$refs[formName].validate((valid) => {
          if (valid) {
            const params = {
              request: {
                userId: this.Viewform.id,
                comId: this.Viewform.comId,
                assessorType: 0,
                type: 2,
                list
              }
            };

            this.submitLoading = true;
            api.save(params).then((res) => {
              this.result = res;
              this.resultVisible = true;
              this.$message.success('人岗评估提交成功');
              this.submitLoading = false;
            });
          }
        });
      }

    },
    handleSure () {
      this.$router.push({ path: '/peoplepostmatch' });
    }
  }

};
</script>


<style lang="scss">
.assessBox .app-container .viewbox .level_box_now {
  font-weight: bold;
}
</style>

<style lang="scss">
.level_box {
  display: flex;
  // background-color: pink;
  .level_box_now {
    // color:#4A8DF0;
    color: #409eff;
    font-weight: bold;
    font-size: 18px;
  }
  .level_box_old {
    color: #ccc;
    font-weight: bold;
    font-size: 18px;
    margin-left: 40px;
  }
  .level_box_btnbox {
    flex: 1;
    display: inline;
    text-align: right;
    // background-color: blue;
    .level_box_btnbox_audit {
      width: 80px;
      // height: 40px;
    }
    .level_box_btnbox_export {
      width: 80px;
    }
  }
  .level_box_levelbox {
    flex: 1;
  }
}
</style>

<style lang="scss">
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .el-form {
      .is-error {
        margin: 30px 0 !important;
      }
      .viewbox {
        .is-error:last-of-type {
          margin-bottom: 0 !important;
        }
      }
      .fwn {
        .el-form-item__label {
          font-weight: normal;
          // transform: translateY(-24px);
          transform: translateY(-10px);
          width: 100%;
          text-align: left;
        }
      }
    }
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
      text-align: center;
      line-height: 17px;
      font: bold 18px/25px "Microsoft YaHei";
      margin: 20px;
      // .title {
      // }
    }
    .viewbox {
      .is-required:last-of-type {
        margin-bottom: 0 !important;
      }
      padding: 30px 0 0 140px;
      background: #fff;
      margin: 0 20px;
      .el-radio {
        margin-right: 20px;
      }
      > p {
        color: #4a8df0;
        font-weight: bold;
        font-size: 18px;
      }
      > div {
        margin-bottom: 14px;
        font: bold 14px/25px "Microsoft YaHei";
        span {
          font-weight: normal;
        }
      }
      > div:nth-child(8) {
        margin-bottom: 0;
      }
      .title {
        font: bold 18px/25px "Microsoft YaHei";
        color: #484848;
      }
      .ptitle {
        font: bold 14px/25px "Microsoft YaHei";
        margin-bottom: 10px;
      }

      .el-textarea {
        width: 80%;
        // margin-left: 10px;
        vertical-align: middle;
        // .el-textarea__inner {
        //   background-color: #f9fbff;
        // }
      }
      > .el-textarea {
        width: 80%;
        margin-left: 0;
      }
      .radioArr {
        margin-left: 30px;
        margin-bottom: 10px;
        .el-radio {
          margin-right: 40px;
        }
      }
    }
    .qualityevaluation {
      padding: 30px 140px 10px 140px;
      background: #fff;
      margin: 0 20px 20px;
      .is-error {
        margin-top: 0 !important;
      }
      div {
        font-weight: bold;
        span {
          font-weight: normal;
          font-size: 14px;
        }
      }
      .title {
        font: bold 18px/25px "Microsoft YaHei";
        margin-bottom: 10px;
      }
      .ptitle {
        font: bold 14px/25px "Microsoft YaHei";
        margin: 10px 0;
      }
      .el-textarea {
        width: 80%;
        margin: 5px 0px;
        vertical-align: middle;
        // .el-textarea__inner {
        //   background-color: #f9fbff;
        // }
      }
      .big-textarea {
        display: block;
        width: 80%;
        margin-left: 0;
      }
      .el-radio-button__inner,
      .el-radio-group {
        display: block;
      }
      .assessRadio {
        .radioArr {
          .el-radio {
            width: 100%;
            margin: 10px 0;
            white-space: pre-wrap;
            text-indent: -14px;
            margin-left: 28px;
          }
        }
      }

      .Talentstype {
        margin-top: 15px;
        .el-form-item__label {
          font-size: 18px;
        }
        .radioArr {
          width: 80%;
          margin-left: 10px;
          .el-radio {
            width: 10%;
            margin: 10px 5px;
          }
        }
      }
      .buttonBox {
        margin-top: 20px;
        padding: 0 25%;
        .el-button {
          width: 100px;
        }
      }
    }
    .Postproposal {
      margin-top: 15px;
      .el-form-item__label {
        font-size: 18px;
      }
      .el-textarea {
        margin-left: 0;
        display: block;
        width: 80%;
        // .el-textarea__inner {
        //   background-color: #f9fbff;
        // }
      }
      .radioArr {
        width: 80%;
        margin-left: 10px;
        // display: flex;
        .el-radio {
          // flex-wrap: wrap;
          width: 25%;
          margin: 10px 5px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.assessBox .app-container .viewbox .viewbox_position {
  color: #3f3f3f;
  font-weight: normal;
}
.assessBox .app-container .qualityevaluation .buttonBox_cancel {
  color: #c5cdda;
  margin-right: 10%;
}
.assessBox .app-container .qualityevaluation .buttonBox_save {
  //   color: #c5cdda;
  margin-right: 10%;
}

.assessBox .app-container .qualityevaluation .Postproposal_input {
  margin-top: 20px;
}
// 覆盖elementUI的样式
.assessBox {
  background-color: #fff;
  .app-container {
    .el-radio__label {
      color: #3f3f3f;
    }
  }
}
</style>
